@layer base {
  .main {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
  text-align: center;
}

/* keep black color for slashes */
.breadcrumbTrail > ol > li {
  color: var(--sk-color-black);
}

/* keep black color for the title */
.breadcrumbTrail span {
  color: var(--sk-color-black);
}

@media (--sk-medium-viewport) {
  .main {
    margin-top: var(--sk-space-40);
  }
}

/* the breadcrumb is NOT displayed on the printed version of the page */

@media print {
  .main {
    display: none;
  }
}

}