@layer base {
  .main {
  display: flex;
  flex: 1;
}

@media (--sk-small-viewport-only) {
  .main {
    flex: 1.5;
  }
}

}