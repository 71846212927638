@layer base {
  .main {
  --field-height: 48px;
  position: relative;
  height: var(--field-height);
}

.main::before {
  content: "";
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 50%;
  bottom: auto;
  left: 0;
  width: 1px;
  height: 50%;
  transform: translateY(-50%);
  background: var(--sk-color-grey-100);
}

.select {
  height: var(--field-height);
}

.select span {
  font-size: var(--sk-font-size-2);
}

.select [class*="sk-Select_selectIcon"] {
  display: none;
}

.select [class*="sk-Select_dropdownContainer"] {
  height: 100%;
}

.select [class*="Dropdown_main__options-list-wrapper"] {
  right: 0;
  width: 140%;
}

.selectField {
  width: auto;
  height: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.selectField:active,
.selectField:focus,
.selectField:focus-visible,
.selectField:hover {
  background-color: var(--sk-color-grey-050);
}

@media (--sk-medium-viewport) {
  .main {
    --field-height: 56px;
  }

  .main .selectField {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0 !important;
  }
}

}