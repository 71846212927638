@layer base {
  .main {
  position: relative;
  margin-top: var(--sk-space-24);
}

.wrapper {
  position: relative;
  align-items: center;
  margin: 0;
  padding: var(--sk-space-16);
  border-radius: var(--sk-space-16);
  background: var(--sk-color-black);
  color: var(--sk-color-white);
  list-style: none;
}

.item {
  position: relative;
  margin: 0;
  margin-bottom: var(--sk-space-16);
  padding: 0;
  padding-bottom: var(--sk-space-16);
  list-style-type: none;
}

.item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 80px;
  height: 1px;
  background-color: var(--sk-color-white);
}

.item:last-child {
  margin: 0;
  padding: 0;
}

.item:last-child::after {
  display: none;
}

.heading {
  font-size: var(--sk-font-size-1);
}

.description {
  display: -webkit-box;
  margin: 0;
  padding-top: var(--sk-space-4);
  overflow: hidden;
  font-size: var(--sk-font-size-2);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (--sk-medium-viewport) {
  .main {
    margin-top: var(--sk-space-24);
  }

  .wrapper {
    display: grid;
    position: relative;
    grid-template-columns: repeat(7, 1fr);
    padding: var(--sk-space-16) var(--sk-space-32);
    color: var(--sk-color-white);
  }

  .item {
    margin: 0;
    margin-right: var(--sk-space-16);
    padding: 0;
    padding-right: var(--sk-space-16);
  }

  .item::after {
    content: "";
    position: absolute;
    inset: 0 0 0 auto;
    width: 1px;
    height: 30%;
    min-height: 24px;
    margin: auto 0;
    opacity: 0.5;
    background-color: #e0e0e6;
  }

  .item:last-child {
    margin: 0;
    padding: 0;
  }

  .item:last-child::after {
    display: none;
  }

  .item___firstOnDesktop {
    grid-column: 1 / 3;
  }

  .item___secondOnDesktop {
    grid-column: 3 / 6;
  }

  .item___thirdOnDesktop {
    grid-column: 6 / 8;
  }

  .contract, .location {
    display: none;
  }
}

@media (--sk-large-viewport) {
  .wrapper {
    padding: var(--sk-space-16) var(--sk-space-32);
  }
}

@media print {
  /* decrease spacing on the printed version of the page */

  .main {
    margin-top: var(--sk-space-4);
  }

  .wrapper {
    padding: 0;
    background-color: transparent;
    color: var(--sk-color-black);
  }

  .item {
    margin-bottom: var(--sk-space-8);
    padding-bottom: var(--sk-space-8);
  }

  .item::after {
    display: none;
  }
}

}