@layer base {
  .main {
  flex-grow: 1;
  background-color: var(--sk-color-white);
}

@media (--sk-medium-viewport) {
  .main {
    flex-grow: 0;
  }
}

}