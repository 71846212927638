@layer base {
  .main {
  flex-grow: 1;
}

.disabled {
  background-color: var(--sk-color-green-200);
  color: var(--sk-color-grey-400);
  pointer-events: none;
}

@media (--sk-medium-viewport) {
  .main {
    flex-grow: 0;
  }
}

}