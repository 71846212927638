@layer base {
  /* Wrapper */
.main {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--sk-space-24) var(--sk-space-16);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-025);
  gap: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .main {
    padding: var(--sk-space-24);
  }
}

.empty {
  overflow: hidden;
}

.empty::after {
  content: "";
  inset: 0;
  position: absolute;
  background-image: url("../../assets/document.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 280px 280px;
}

.loading::after {
  animation: animate-background ease-in-out 2.5s infinite;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: fade-in 1s;
  gap: var(--sk-space-16);
}

/* Generated contents */
.result p, .result ul {
  margin: 0;
  padding: 0;
}

.result h3 {
  margin: 0;
}

.result h3:not(:first-child) {
  margin-top: var(--sk-space-8);
}

.result li {
  margin-left: var(--sk-space-16);
}

/* Animations */

@keyframes animate-background {
  0% {
    background-size: 280px 280px;
  }

  50% {
    transform: rotate(30deg);
    background-size: 0 0;
  }

  51% {
    transform: rotate(-30deg);
    background-size: 0 0;
  }

  100% {
    background-size: 280px 280px;
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

}