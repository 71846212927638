@layer base {
  .container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-24);
  align-items: center;
}

.aiCoverLetterDivider {
  align-self: center;
  height: var(--sk-space-24);
}

@media (--sk-small-viewport-only) {
  .aiCoverLetterDivider,
  .aiCoverLetterButton {
    display: none;
  }
}

/* the candidate actions are NOT displayed on the printed version of the page */

@media print {
  .container {
    display: none;
  }
}

}